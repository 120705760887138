// matching gradient
$one: #533F75;
$two: #385A98;
$three: #0076B0;
$four: #0090BA;
$five: #00A9B5;
$six: #00BFA5;

// main colors
$primary: $one;
$secondary: $six;

// grays
$gray-50: #f9f9fa;
$gray-100: #eeedef;
$gray-200: #e1e0e4;
$gray-300: #d4d2d8;
$gray-400: #c5c3ca;
$gray-500: #b5b1bb;
$gray-600: #a29eaa;
$gray-700: #8c8796;
$gray-800: #70697c;
$gray-900: #433c4d;
$gray2-50: #f9f9fb;
$gray2-100: #ededf2;
$gray2-200: #dfe0e8;
$gray2-300: #d1d2de;
$gray2-400: #c1c3d2;
$gray2-500: #afb2c6;
$gray2-600: #9b9fb7;
$gray2-700: #8387a6;
$gray2-800: #646a90;
$gray2-900: #363c65;
