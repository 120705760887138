.ervn-limit-content-width {
  max-width: 800px;
}

.ervn-oscillate {
  opacity: 1;
  animation: oscillate 3s infinite;
}

@keyframes oscillate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
