@use '../../styles/colors.scss';

#ErvnHome {
  height: 100vh;
  background-color: colors.$one;
  animation: background-color 7s infinite;

  h1 {
    color: colors.$gray-50;
    margin: 0;
  }

  h2 {
    color: colors.$gray2-500;
    font-size: min(1.5em, 6vw);
    margin: 8px 0 0 0;
  }

  .ervn-header {
    left: 0;
    margin: auto;
    max-width: 1200px;
    padding: 32px 48px 0;
    position: fixed;
    right: 0;
  }

  .ervn-content {
    height: calc(100vh - 100px);
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 110px;

    a {
      color: colors.$gray2-400;

      &:hover {
        color: scale-color(colors.$six, $lightness: 10%);
      }
    }

    p {
      color: colors.$gray2-500;
      line-height: 1.5;
    }

    h4 {
      line-height: 1.5;
    }

    h3,
    h4 {
      color: scale-color(colors.$six, $lightness: 50%);
      font-weight: 300;
    }

    .ervn-content-container {
      margin: auto;
      max-width: 1200px;
      padding: 0 48px 48px;

      .ervn-career {
        padding-top: 50px;
      }

      .ervn-career-small {
        padding-top: 16px;
      }

      .ervn-intro {
        padding-top: 8px;
      }
    }

    .ervn-icon-header {
      align-items: center;
      display: flex;

      a {
        margin: 0;
        padding: 0;
      }

      h3 {
        margin-left: 12px;
      }
    }
  }
}

@keyframes background-color {
  0% {
    background-color: scale-color(colors.$one, $lightness: -70%);
  }
  50% {
    background-color: scale-color(colors.$three, $lightness: -70%);
  }
  100% {
    background-color: scale-color(colors.$one, $lightness: -70%);
  }
}
