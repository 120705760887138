@use '../../styles/colors.scss';

.ervn-portfolio-card {
  align-items: center;
  border: 1px solid colors.$gray-800;
  display: flex;
  border-radius: 4px;
  height: 100%;
  padding: 16px;
  position: relative;
  width: 100%;

  &:hover {
    // border-color: colors.$gray-700;
    opacity: 0.8;
  }

  img {
    width: 100%;
  }

  .ervn-portfolio-card-background {
    background-color: colors.$gray-50;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0.28;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
