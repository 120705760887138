.ervn-phos-examples {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 1fr);

  .ervn-example-container {
    display: flex;
  }
}

@media screen and (max-width: 499px) {
  .ervn-phos-examples {
    grid-template-columns: 1fr;
  }
}
